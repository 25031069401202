<template>
  <div class="parking-detail-container" v-if="detailInfo">
    <ContentTitle>
      <span class="return" @click="handleReturn">
        <i class="iconfont icon-a-lujing46"> </i>
        <span>返回</span>
      </span>
      <el-divider direction="vertical"></el-divider>
      车场详情</ContentTitle
    >
    <div class="parking-detail-content">
      <div class="parking-info">
        <div class="info">
          <div class="title margin-r20">{{ detailInfo.name }}</div>
          <div class="margin-r20 font13" v-if="detailInfo.template">
            临停收费方式：<span
              v-for="(el, index) in detailInfo.template"
              :key="index"
              >{{ el.name }}
              <span v-if="index != detailInfo.template.length - 1">、</span>
            </span>
          </div>
          <div class="margin-r20 font13">
            呼叫中心：<span>{{ detailInfo.call_tel }}</span>
          </div>
        </div>
        <div
          class="add-btn"
          @click="openEditParkingDialog"
          v-if="showEditParkingBtn"
        >
          编辑
        </div>
      </div>
      <div class="table-container">
        <div class="table-action">
          <div class="table-title">道闸管理</div>
          <div
            class="add-btn"
            @click="openGateMachineDialog"
            v-if="showAddMachine"
          >
            新增闸机
          </div>
        </div>

        <template
          v-if="detailInfo && detailInfo.gate && detailInfo.gate.length"
        >
          <Wtable
            :columns="gateMachineColumns"
            :pagination="gateMachinePagination"
            :tableData="detailInfo.gate"
          >
            <template #status="{ row }">
              <el-switch
                v-model="row.status"
                @click="changeStatus(row)"
                inline-prompt
                active-text="启用"
                inactive-text="关闭"
                :disabled="canChangeStatus == 0 ? true : false"
              >
              </el-switch>
            </template>
          </Wtable>
        </template>
        <template v-else>
          <el-empty description="暂无闸机数据"></el-empty>
        </template>
      </div>
      <!-- <div class="table-container padding-b64">
        <div class="table-action">
          <div class="table-title">电桩管理</div>
          <div class="add-btn" @click="openElectricFittingDialog">新增电桩</div>
        </div>
        <template v-if="electricFittingTableData.length">
          <Wtable
            :columns="electricFittingColumns"
            :pagination="electricFittingPagination"
            :tableData="electricFittingTableData"
            @delete="deleteElectricFittingRow"
          ></Wtable>
        </template>
      </div> -->
    </div>
    <GateMachineDialog
      ref="gateMachineDialog"
      @submit="getGateMachineTableData"
      :needParking="false"
    ></GateMachineDialog>
    <ElectricFittingDialog
      ref="electricFittingDialog"
      @submit="getElectricFittingTableData"
    ></ElectricFittingDialog>
    <EditParkingDialog
      ref="editParkingDialog"
      @submit="editParking"
    ></EditParkingDialog>
  </div>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Wtable from "@/components/wTable/wTable.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import ElectricFittingDialog from "../components/ElectricFittingDialog.vue";
import EditParkingDialog from "../components/EditParkingDialog.vue";
import GateMachineDialog from "../components/GateMachineDialog.vue";
import API from "@/plugins/api.js";
import config from "./parkingDetail.json";

export default {
  components: {
    Wtable,
    ContentTitle,
    ElectricFittingDialog,
    EditParkingDialog,
    GateMachineDialog,
  },
  setup() {
    const router = useRouter();
    const { query } = useRoute();
    const detailInfo = ref({});
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    const showEditParkingBtn = ref(false);
    const showAddMachine = ref(false);
    const canChangeStatus = ref(0);
    // 获取权限按钮
    if (rightBtn.value && rightBtn.value.length) {
      rightBtn.value.forEach((el) => {
        if (el.api_menu_token == "Btn_mFeKTyc9rKhky8gxphnzQQtwE87B") {
          //编辑车场的权限
          showEditParkingBtn.value = true;
        } else if (el.api_menu_token == "Btn_zOpd1jY2Zhi6V3eAVfopKInLvJvy") {
          // 新增闸机的操作权限
          showAddMachine.value = true;
        } else if (el.api_menu_token == "Btn_nWXfoLqJu7Hdpss7s1yT2GdktW7Q") {
          // 改变状态的操作权限
          canChangeStatus.value = 1;
        }
      });
    }
    function fetchData() {
      API.getParkingDetail({ id: query.id }).then((res) => {
        if (res.gate.length) {
          res.gate.forEach((el) => {
            el.status == 1 ? (el.status = true) : (el.status = false);
          });
        }
        detailInfo.value = res;
      });
    }
    onMounted(() => {
      fetchData();
    });
    function handleReturn() {
      router.push("/parking");
    }

    const editParkingDialog = ref(null);
    function openEditParkingDialog() {
      editParkingDialog.value.openDialog(detailInfo.value);
    }
    function editParking(data) {
      const parmas = {
        id: data.id,
        name: data.name,
        project_id: data.project_id,
        template_id: data.template_id,
        call_tel: data.call_tel,
      };
      API.editParking(parmas).then(() => {
        ElMessage.success({
          message: "操作成功！",
        });
        fetchData();
        editParkingDialog.value.closeDialog();
      });
    }
    const gateMachineColumns = config.gateMachineTableConfig.list;
    const gateMachinePagination = config.gateMachineTableConfig.pagination;

    const gateMachineDialog = ref(null);
    function openGateMachineDialog() {
      gateMachineDialog.value.openDialog();
    }
    function getGateMachineTableData(data) {
      const parmas = {
        sn: data.sn,
        name: data.name,
        carpark_id: detailInfo.value.id,
        resources: data.resources,
        status: data.status,
        gate_position_id: data.gate_position_id,
        gate_type_id: data.gate_type_id,
        charge: data.charge,
      };
      API.addGateMachine(parmas).then(() => {
        ElMessage.success({
          message: "操作成功",
        });
        gateMachineDialog.value.closeDialog();
        fetchData();
      });
    }
    function changeStatus(data) {
      if (canChangeStatus.value) {
        const status = data.status == true ? 1 : 0;
        API.editGateMachineStatus({
          id: data.id,
          status: status,
        }).then(() => {
          ElMessage.success({
            message: "操作成功！",
          });
          fetchData();
        });
      } else {
        ElMessage.warning({
          message: "暂无权限操作！",
        });
        return false;
      }
    }
    const electricFittingDialog = ref(null);
    const electricFittingColumns = config.electricFittingTableConfig.list;
    const electricFittingPagination =
      config.electricFittingTableConfig.pagination;

    const electricFittingTableData = ref([
      {
        electricFitting: "选项2",
        electricFittingType: "cc",
        onlineStatus: "选项1",
        equipmentNumber: "选项1",
        video: "https://www.w3school.com.cn/i/movie.ogg",
        status: false,
      },
      {
        electricFitting: "选项2",
        electricFittingType: "cc",
        onlineStatus: "选项1",
        equipmentNumber: "选项1",
        video: "",
        status: false,
      },
    ]);
    function openElectricFittingDialog() {
      electricFittingDialog.value.openDialog();
    }
    function getElectricFittingTableData(data) {
      electricFittingTableData.value.push(data);
    }

    function deleteElectricFittingRow(data) {
      electricFittingTableData.value.splice(data.$index, 1);
    }

    return {
      handleReturn,
      gateMachineColumns,
      openGateMachineDialog,
      gateMachineDialog,
      getGateMachineTableData,
      electricFittingDialog,
      getElectricFittingTableData,
      electricFittingColumns,
      electricFittingTableData,
      openElectricFittingDialog,
      deleteElectricFittingRow,
      openEditParkingDialog,
      editParkingDialog,
      editParking,
      gateMachinePagination,
      electricFittingPagination,
      detailInfo,
      changeStatus,
      showEditParkingBtn,
      showAddMachine,
      canChangeStatus,
    };
  },
};
</script>

<style lang='scss' scoped>
.parking-detail-container {
  height: 100%;
  background-color: #fff;
  padding: 0 20px;
  position: relative;
  .parking-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--theme-bg-color);
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 20px;
    .info {
      display: flex;
      align-items: center;
      flex: 1;
      .title {
        font-weight: bold;
      }
      .margin-r20 {
        margin-right: 20px;
      }
      .font13 {
        font-size: 13px;
      }
    }
  }
  .add-btn {
    cursor: pointer;
    color: var(--theme-color);
    width: 80px;
    height: 30px;
    line-height: 30px;
    border: 1px solid var(--theme-color);
    border-radius: 10px;
    font-size: 13px;
    text-align: center;
    &:hover {
      color: #ffffff;
      background-color: var(--theme-color);
      border: 1px solid var(--theme-color);
    }
  }
  .parking-detail-content {
    .table-container {
      .table-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        .table-title {
          color: var(--text-third-color);
        }
      }
    }
  }
}
</style>