<template>
  <div class="electric-fitting-dialog">
    <Wdialog
      ref="electricFittingDialog"
      title="新增电桩"
      width="50%"
      @wConfirm="handleAddElectricFitting"
    >
      <el-form
        ref="electricFittingFormRef"
        :model="electricFittingFormData"
        :rules="electricFittingFormRules"
        label-position="top"
        class="electric-fitting-form"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="electricFitting" label="充电桩名称">
              <el-select
                v-model="electricFittingFormData.electricFitting"
                placeholder="请选择充电桩名称"
              >
                <el-option
                  v-for="item in electricOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="electricFittingType" label="充电桩类型">
              <el-select
                v-model="electricFittingFormData.electricFittingType"
                placeholder="请选择充电桩类型"
              >
                <el-option
                  v-for="item in electricTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="equipmentNumber" label="设备编号">
              <el-input
                v-model="electricFittingFormData.equipmentNumber"
                autocomplete="off"
                placeholder="请输入设备编号"
                clearable
              /> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="video" label="视频摄像头">
              <el-select
                v-model="electricFittingFormData.video"
                placeholder="请选择视频摄像头"
              >
                <el-option
                  v-for="item in cameraOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="status" label="启用状态">
              <el-select
                v-model="electricFittingFormData.status"
                placeholder="请选择启用状态"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>

<script>
import { ref } from "vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
export default {
  components: {
    Wdialog,
  },

  emits: ["submit"],
  setup(props, { emit }) {
    const electricFittingDialog = ref(null);
    const electricFittingFormRef = ref(null);
    const electricFittingFormRules = {
      equipmentNumber: [
        { required: true, message: "请输入设备编号", trigger: "blur" },
      ],
      electricFittingType: [
        { required: true, message: "请选择充电桩类型", trigger: "blur" },
      ],
      electricFitting: [
        { required: true, message: "请选择充电桩名称", trigger: "blur" },
      ],
      video: [
        { required: true, message: "请选择视频摄像头", trigger: "blur" },
      ],
      status: [
        {
          required: true,
          message: "请选择启用状态",
          trigger: "blur",
        },
      ],
    };
    const electricOptions = ref([
      {
        value: "选项1",
        label: "充电桩1",
      },
      {
        value: "选项2",
        label: "双皮奶",
      },
    ]);
    const electricTypeOptions = ref([
      {
        value: "选项1",
        label: "充电桩类型1",
      },
      {
        value: "选项2",
        label: "双皮奶",
      },
    ]);
    const cameraOptions = ref([
      {
        value: "选项1",
        label: "摄像头1",
      },
      {
        value: "选项2",
        label: "双皮奶",
      },
    ]);

    const statusOptions = ref([
      {
        value: false,
        label: "否",
      },
      {
        value: true,
        label: "是",
      },
    ]);
    const electricFittingFormData = ref({
      electricFitting: "",
      electricFittingType: "",
      equipmentNumber: "",
      video: "",
      status: "",
    });
    function handleAddElectricFitting() {
      if (electricFittingFormRef.value) {
        electricFittingFormRef.value.validate((valid) => {
          if (valid) {
            emit("submit", electricFittingFormData.value);
            electricFittingDialog.value.close();
          }
        });
      }
    }
    function openDialog() {
      electricFittingFormData.value = {
        electricFitting: "",
        electricFittingType: "",
        equipmentNumber: "",
        video: "",
        status: "",
      };
      electricFittingDialog.value.show();
    }
    return {
      electricFittingFormData,
      electricFittingFormRules,
      electricFittingFormRef,
      handleAddElectricFitting,
      openDialog,
      electricFittingDialog,
      statusOptions,
      electricOptions,
      electricTypeOptions,
      cameraOptions,
    };
  },
};
</script>

<style lang="scss">
.electric-fitting-dialog {
  .electric-fitting-form {
    padding-bottom: 150px;
  }
}
</style>
