<template>
  <div class="edit-Parking-dialog">
    <Wdialog
      ref="editParkingDialog"
      title="编辑车场信息"
      width="40%"
      @wConfirm="handleEditParking"
    >
      <el-form
        ref="editParkingFormRef"
        :model="editParkingFormData"
        :rules="editParkingFormRules"
        label-position="top"
        class="edit-Parking-form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="name" label="车场名称">
              <el-input
                v-model="editParkingFormData.name"
                autocomplete="off"
                placeholder="请输入车场名称"
                clearable
              /> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item prop="project_id" label="所属项目">
              <el-select
                v-model="editParkingFormData.project_id"
                placeholder="请选择所属项目"
                @change="changeProject"
              >
                <el-option
                  v-for="item in projectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="template_id" label="临停收费模板">
              <el-select
                v-model="editParkingFormData.template_id"
                placeholder="请选择临停收费模板"
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in chargeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item prop="call_tel" label="呼叫中心">
              <el-input
                v-model="editParkingFormData.call_tel"
                autocomplete="off"
                placeholder="请输入呼叫中心"
                clearable
              /> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import API from "@/plugins/api.js";
import Wdialog from "@/components/wDialog/wDialog.vue";
export default {
  components: {
    Wdialog,
  },

  emits: ["submit"],
  setup(props, { emit }) {
    const editParkingDialog = ref(null);
    const editParkingFormRef = ref(null);
    const editParkingFormRules = {
      name: [{ required: true, message: "请选择车场名称", trigger: "blur" }],
      project_id: [{ required: true, message: "请选择所属项目", trigger: "change" }],
      template_id: [
        { required: true, message: "请选择临停收费模板", trigger: "change" },
      ],

      call_tel: [
        { required: true, message: "请输入呼叫中心", trigger: "blur" },
      ],
    };
    const projectOptions = ref([]);
    onMounted(() => {
      API.getProjectOptions({}).then((res) => {
        projectOptions.value = res;
      });
    });
    const editParkingFormData = ref({
      name: "",
      project_id: "",
      template_id: [],
      call_tel: "",
    });
    const chargeTypeOptions = ref([]);
    watch(
      () => editParkingFormData.value.project_id,
      (v) => {
        if (v) {
          API.getChargeTemplateOptions({ project_id: v }).then((res) => {
            chargeTypeOptions.value = res;
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    function changeProject() {
      editParkingFormData.value.template_id = [];
    }
    function handleEditParking() {
      if (editParkingFormRef.value) {
        editParkingFormRef.value.validate((valid) => {
          if (valid) {
            emit("submit", editParkingFormData.value);
          }
        });
      }
    }
    function openDialog(data) {
      editParkingFormData.value = JSON.parse(JSON.stringify(data));
      editParkingDialog.value.show();
    }
    function closeDialog() {
      editParkingDialog.value.close();
    }
    return {
      editParkingFormData,
      editParkingFormRules,
      editParkingFormRef,
      handleEditParking,
      chargeTypeOptions,
      openDialog,
      editParkingDialog,
      projectOptions,
      changeProject,
      closeDialog,
    };
  },
};
</script>

<style lang="scss">
.edit-Parking-dialog {
  .edit-Parking-form {
    padding-bottom: 20px;
  }
}
</style>
